import React, {FC} from 'react';

import useFetchSubscriptions from '../hooks/subscriptions/useFetchSubscriptions';

import BecomeMemberSectionComponent from '../components/BecomeMemeberPage/BecomeMemberSectionComponent';
import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextWrapper from '../components/UIWrappers/UITextWrapper';
import UICtaComponent from '../components/UICommon/UICtaComponent';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';

const BecomeMemberPage: FC = () => {
  const {isLoading: isLoadingPage, isError: isErrorPage} = useFetchSubscriptions();

  if (isErrorPage) {
    return (
      <UIErrorIndicator
        message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'
      />
    );
  }

  if (isLoadingPage) {
    return <UILoadingIndicator/>;
  }

  return (
    <UIPageContainer variant='wide'>
      <UITextWrapper
        heading='MEDLEMSKAP'
        title='medlemskap som passar just er, FÖR företag av alla storlekar.'
        description='Nu ska vi bara hitta ett medlemskap som passar
        just er. Vi har medlemskap som passar företag av alla storlekar.'
      />
      <BecomeMemberSectionComponent
        isUpgrading={false}
      />
      <UICtaComponent
        title='Vill du bli partner?'
        description='Ansökan om medlemskap är bindande. Var noga med att fylla i rätt uppgifter.
        Vi tar ut en avgift på 200 kr ex moms vid omfakturering.
        Medlemskap gäller för innevarande månad och 11 månader framåt.
        Därefter förlängs medlemskapet om du inte meddelar uppsägning.
        Uppsägning ska ske skriftligen till canita@mih.nu senast 1 månad innan medlemskapet löper ut.
        OBS! Priserna är exklusive moms och våra fakturor skickas med epost.
         Önskas e-fakturering kontakta ekonomi@mih.nu'
        buttonTitle='Bli partner'
        buttonUrl='mailto:canita@mih.nu?subject=Förfrågan bli partner MiH'
        variant='white'
        contactInfo='canita@mih.nu'
        extraInfo={`<p>15 000 – 100 000 kr</br>
          För mer information kontakta</p>`}
      />
    </UIPageContainer>
  );
};

export default BecomeMemberPage;
